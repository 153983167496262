// FavouritesContext.js
import React, { createContext, useContext, useState } from 'react';

const FavouritesContext = createContext();

export const useFavourites = () => useContext(FavouritesContext);

export const FavouritesProvider = ({ children }) => {
  const [favourites, setFavourites] = useState([]);

  const removeAllFavourites = () => {
    setFavourites([])
    console.log(favourites)
  };

  const removeFromFavourites = (event) => {
    const updatedFavourites = favourites.filter(item => item !== event);
    setFavourites(updatedFavourites);
  };


  const addToFavourites = (event) => {
    setFavourites([...favourites, event]);
  };

  return (
    <FavouritesContext.Provider value={{ favourites, addToFavourites , removeFromFavourites,removeAllFavourites}}>
      {children}
    </FavouritesContext.Provider>
  );
};
