/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import "./Event.css";
import { useNavigate } from 'react-router-dom';
import eventList from '../../data/EventList';
import Cookies from 'js-cookie';
import ImgAliyataEsa from '../../img/EventImage01/Bristol Avrudu/Aliyata esa mobile.webp'
import ImgAwuruduKumaraKumariAdult from '../../img/EventImage01/Bristol Avrudu/New Awurudu Kumari .webp'
import ImgAwuruduKumaraKumariChild from '../../img/EventImage01/Bristol Avrudu/Singithi avrudu kumara.webp'
import ImgBaloonAllima from '../../img/EventImage01/Bristol Avrudu/balloon mobile.webp'
import ImgBothalayataWathura from '../../img/EventImage01/Bristol Avrudu/bothalayata wathura mobile.webp'
import ImgDehiGediya from '../../img/EventImage01/Bristol Avrudu/Dehi gediya mobile.webp'
import ImgHodamaEdima from '../../img/EventImage01/Bristol Avrudu/Edeema mobile.webp'
import ImgGaslabuGediya from '../../img/EventImage01/Bristol Avrudu/Gaslabu eta mobile.webp'
import ImgHodamaHinawa from '../../img/EventImage01/Bristol Avrudu/Hinawa mobile.webp'
import ImgKambaEdima from '../../img/EventImage01/Bristol Avrudu/Kamba edima mobile.webp'
import ImgKanaMutti from '../../img/EventImage01/Bristol Avrudu/kana mutti mobile.webp'
import ImgKanthaDiwimaUnMarried from '../../img/EventImage01/Bristol Avrudu/Kantha diweema mobile.webp'
import ImgKanthaDiwimaMarried from '../../img/EventImage01/Bristol Avrudu/Kantha diwieema married (1).webp'
import ImgPirimiDiwimaUnMarried from '../../img/EventImage01/Bristol Avrudu/Pirimi diwiima unmarried.webp'
import ImgPirimiDiwimaMarried from '../../img/EventImage01/Bristol Avrudu/Pirimi diwiima.webp'
import ImgKetaGesima from '../../img/EventImage01/Bristol Avrudu/Keta Gesima mobile.webp'
import ImgKiriKewima from '../../img/EventImage01/Bristol Avrudu/Kiri kewima.webp'
import ImgPollaWate from '../../img/EventImage01/Bristol Avrudu/Polla wate mobile.webp'
import ImgSangeethaPutu from '../../img/EventImage01/Bristol Avrudu/Sangeetha putu mobile.webp'
import ImgSegawunuAmuththa from '../../img/EventImage01/Bristol Avrudu/Segauna amuththa mobile.webp'
import ImgSuppuwenWathura from '../../img/EventImage01/Bristol Avrudu/Suuppwen wathura mobile.webp'
import ImgToffeEhidima from '../../img/EventImage01/Bristol Avrudu/Toffee ehindieema mobile.webp'
import ImgWikataEdum from '../../img/EventImage01/Bristol Avrudu/Wikata edum mobile.webp'
import ImgIdiKattataNulaDemima from '../../img/EventImage01/Bristol Avrudu/indikatta ta nuula.webp'
import ImgBanisKema from '../../img/EventImage01/Bristol Avrudu/Banis kema.webp'
import ImgGoniPenima from '../../img/EventImage01/Bristol Avrudu/Goni penima.webp'
import ImgBolaEllima from '../../img/EventImage01/Bristol Avrudu/Bola alleema.webp'
import ImgSerappu from '../../img/EventImage01/Bristol Avrudu/sereppu.webp'
import ImgThoppiTharagaya from '../../img/EventImage01/Bristol Avrudu/Thoppi tharagaya.webp'
import ImgBadakaDiwima from '../../img/EventImage01/Bristol Avrudu/Baadaka diweema.webp'
import ImgKottapora from '../../img/EventImage01/Bristol Avrudu/Kottapora.webp'
import ImgCricket from '../../img/EventImage01/Bristol Avrudu/CricketGame.webp'
import ImgBalloonDance from '../../img/EventImage01/Bristol Avrudu/BallonDance.webp'
import newTag from '../../img/newTag03.png'


const Events = () => {

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [language, setLanguage] = useState()

  const preferredlanguage = Cookies.get('preferred_language');
  useEffect(() => {
    setLanguage(preferredlanguage)
  }, [preferredlanguage]);


  const navigate = useNavigate();


  useEffect(() => {
    setFilteredEvents(eventList.filter(eventItem =>
      eventItem.event_name.toLowerCase().includes(searchQuery.toLowerCase())
    ));
  }, [searchQuery]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (eventId) => {
    navigate(`/event-details/${eventId}`);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <section className='events-section-area'>
        <div className='event-page-title'>
          <h1 data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500" >Games</h1>
          <p data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">Join us in celebrating Sinhala Hindu New Year! Register now to immerse yourself in festivities and traditions</p>
          <div className='event-search-container'>
            <input type='text' placeholder='Search' value={searchQuery} onChange={handleSearchChange} />
          </div>
        </div>

        <div className='events-list-area desktop'>
          {filteredEvents.map((eventItem, index) => (
            <a onClick={() => handleClick(eventItem.eventId)} key={index}>
              <div class="carousel-item">

                {eventItem?.isNewTag === true ?
                  <div className='tag-container'>
                    <img style={{ width: '100%' }} src={newTag} alt='new tag' />
                  </div> : <div></div>
                }

                <img class={`carousel-item__img ${eventItem.eventId === 1 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 1 ? ImgKottapora : ''} alt="Kotta Pora" />
                <img class={`carousel-item__img ${eventItem.eventId === 2 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 2 ? ImgKambaEdima : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 3 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 3 ? ImgIdiKattataNulaDemima : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 4 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 4 ? ImgKiriKewima : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 5 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 5 ? ImgBanisKema : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 6 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 6 ? ImgAliyataEsa : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 7 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 7 ? ImgBadakaDiwima : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 8 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 8 ? ImgSegawunuAmuththa : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 9 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 9 ? ImgKetaGesima : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 10 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 10 ? ImgGoniPenima : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 11 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 11 ? ImgKanaMutti : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 12 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 12 ? ImgAwuruduKumaraKumariAdult : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 33 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 33 ? ImgAwuruduKumaraKumariChild : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 13 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 13 ? ImgBaloonAllima : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 14 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 14 ? ImgDehiGediya : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 15 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 15 ? ImgHodamaHinawa : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 16 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 16 ? ImgHodamaEdima : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 17 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 17 ? '' : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 18 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 18 ? ImgWikataEdum : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 19 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 19 ? ImgSangeethaPutu : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 20 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 20 ? ImgGaslabuGediya : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 21 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 21 ? ImgSuppuwenWathura : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 22 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 22 ? ImgKanthaDiwimaUnMarried : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 23 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 23 ? ImgPollaWate : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 24 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 24 ? ImgBothalayataWathura : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 25 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 25 ? ImgToffeEhidima : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 26 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 26 ? ImgThoppiTharagaya : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 27 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 27 ? ImgBolaEllima : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 28 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 28 ? '' : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 29 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 29 ? ImgSerappu : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 30 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 30 ? ImgKanthaDiwimaMarried : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 31 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 31 ? ImgPirimiDiwimaMarried : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 32 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 32 ? ImgPirimiDiwimaUnMarried : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 34 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 34 ? ImgCricket : ''} alt="people" />
                <img class={`carousel-item__img ${eventItem.eventId === 35 ? "carousel-item__img-displayBlock" : "carousel-item__img-displayNone"}`} src={eventItem.eventId === 35 ? ImgBalloonDance : ''} alt="people" />

                <div class="carousel-item__details">
                  <div class="controls">
                    <span class="fas fa-play-circle"></span>
                    <span class="fas fa-plus-circle"></span>
                  </div>
                  <h1 class="carousel-item__details--title" style={{ fontSize: '1rem' }}>{language === "Sinhala" ? eventItem.event_name : eventItem.event_name_eng}</h1>
                </div>
              </div>
            </a>
          ))}

        </div>

        <div className='events-list-area-mobile mobile'>
          {filteredEvents.map((eventItem, index) => (

            <a onClick={() => handleClick(eventItem.eventId)} key={index}><div className='events-list-area-mobile-item'><h1>{language === "Sinhala" ? eventItem.event_name : eventItem.event_name_eng}</h1>
              {eventItem?.isNewTag === true ?
                <div className='tag-container-mobile'>
                  <img style={{ width: '100%' }} src={newTag} alt='new tag' />
                </div> : <div></div>
              }
            </div></a>
          ))}
        </div>


        <h1 className='more-game-coming-soon-txt'>More events coming soon !</h1>
      </section>
    </>

  )
}

export default Events