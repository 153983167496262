/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from 'react';
import './RegisterForGame.css';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import eventList from '../../data/EventList';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useFavourites} from '../../context/FavouritesContext';

const RegisterForGame = () => {
  const [submitted, setSubmitted] = useState(false);
  const [isLoadnig, setIsLoading] = useState(false);
  const navigate = useNavigate();


  const { favourites } = useFavourites();
  const { removeAllFavourites } = useFavourites();

  const [eventArray, setEventArray] = useState([{
    value: '',
    label: ''
  }])

  const [selectedFilter, setSelectedFilter] = useState('All');

  const [language , setLanguage] = useState()

  const preferredlanguage = Cookies.get('preferred_language');
  useEffect(() => {    
    setLanguage(preferredlanguage)
  }, [preferredlanguage]);

  useEffect(() => {    
    
    const favouriteEvent = favourites.map(favourite => favourite);
    setFormData({ ...formData, selectedEvents: [...formData.selectedEvents, ...favouriteEvent] });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Filter events based on the selected filter
  const filteredEvents = selectedFilter === 'All' ? eventList : eventList.filter(event => event.can_participate.includes(selectedFilter));

  useEffect(() => {
    // eslint-disable-next-line no-whitespace-before-property
    eventList.map((event) => { setEventArray(event?.event_name) })
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleCheckboxChange(event) {

    const { value, checked } = event.target;

    if (checked) {
      setFormData({ ...formData, selectedEvents: [...formData.selectedEvents, value] });
    } else {
      setFormData({ ...formData, selectedEvents: formData.selectedEvents.filter(event => event !== value) });
    }
  }

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const animatedComponents = makeAnimated();
  const [formData, setFormData] = useState({
    fullName: '',
    category: '',
    selectedEvents: []
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEventSelect = (selectedEvents) => {
    setFormData({ ...formData, selectedEvents });
  };


  function getUrlForEvent(event) {
    const eventUrls = {
      'Kotta Pora': "https://script.google.com/macros/s/AKfycbzI9Ant1GQB4Z9zzQSxnIwt5YHQZn5G9tgOkYmMOCBUD1JD8nB8KKGIdgWdm2kI2637/exec",
      'Kamba Edima': "https://script.google.com/macros/s/AKfycbx02Hu_JNJfCm184A9kzWw91rfYo6C3QqJCK6RnUY8qpe37dbXejhwWdCo1sxrYYwTc/exec",
      'Idi Kattata Nula Demima': "https://script.google.com/macros/s/AKfycbw0f08M_5-z_vUKnMTm3Wmwik0Pi8rxAVp8BwbRsmF2RJ2YQY90FAo9d3wyA2Xn32C6/exec",
      'Kiri Peni Kema': "https://script.google.com/macros/s/AKfycbyHHb6oMT5Knq55CVzwJWHwfa8rYhwkA5lIadFYR5Eld6n7oFwaPXKZm8st6KMVHPbI/exec",
      'Banis Kema': "https://script.google.com/macros/s/AKfycbym4dsSR-B0Va8__hGO6Fy8nDwpksBQ95Vx_HbjTeiT43FotqoJvj7LVd5P8OL4h_HD/exec",
      'Aliyat Asa Thebima': "https://script.google.com/macros/s/AKfycbxFev0WNcjDk4hqtjpDuYuaKaWq184lB99BpMbm_-IQWC8N2DwAXSBDaGodgo7wEfU/exec",
      'Badaka Diwima': "https://script.google.com/macros/s/AKfycbxG93uEUEh3bJBd7NSxb3MFwYglxh3Kyn7vFwtUVHEEZJuzF63sAbEBmTEa6czzFrYX/exec",
      'Segawunu Amuththa': "https://script.google.com/macros/s/AKfycbxxu_1tnpyDQmFFMMIUvuXgNZg8h9VbFUpaUPB5s2ynHOG2eVuGAWGAjJzwf5enGQU/exec",
      'Keta Gesima': "https://script.google.com/macros/s/AKfycbweuuPILn-D-KANiInjrvUYWpjNhqwXgvNzY6xr1Z3mZH5Z8hFr8yrx7a-MOfT5VttJ/exec",
      'Goni Penima': "https://script.google.com/macros/s/AKfycbyQxNvi3155sw-j4KJ0xST47WsvXGu95AhX2Zp7vgTVcKxHOoyaFyseO32Kc-QDSfqm/exec",
      'Kana Mutti Bidima': "https://script.google.com/macros/s/AKfycbwZyNLUUkQMGIGBfxrB-GHeH5DGO1x5dgXLIzybXnQPU78zbIiaAQb9LdAGH7M1zacN/exec",
      'Awurudu Kumara/Kumari (Child)': "https://script.google.com/macros/s/AKfycbze4q-XdqXb7fSDiI0i_ETU4eZnu0_pqIgqy_uw2j9ExD3eSOI_z-pBVYC0Xmt4uDtx/exec",
      'Awurudu Kumara/Kumari (Adult)':"https://script.google.com/macros/s/AKfycbwUl8KJXn4YYblbkVx9rHDE35bScOBlSaKcnCmOSkt8H_O3uk3AqNgR8VvUzeD54VCF/exec",
      'Balum Pipirawima': "https://script.google.com/macros/s/AKfycbw6W34DU2kcLdFAJ5agZvJbqet48BPuBSpGpHcjickOYChHquLFhj8RtwYe7yfRPG7B/exec",
      'Dehi Gediya Hende Thiyan Diwima': "https://script.google.com/macros/s/AKfycbzkbeah1vYEMeVIP3Zqs9G2c6zmZwHxsqKwkEj2HUlGk7it0AAZttfBcUPBaWAQVlZH/exec",
      'Hodama Hinawa': "https://script.google.com/macros/s/AKfycbyJG_M-_F9AjkBpFDlxX2pXGb8bxdeWTVABdI5VA7BC0YXeXnxIqNOQFGDjlqoWbwdC/exec",
      'Hodama Adima': "https://script.google.com/macros/s/AKfycbxIPDWdq61BXfWjYDXWL4QMXCw-LRVxMg7__xdIAjE_1YRaoB3aE6mBF4IRYbQWhq9O/exec",
      'Digama Huwa': "https://script.google.com/macros/s/AKfycbzeI3cIRYIYoL2itTkfAni19SspHoqguxHWQXf7UmVUyjvyGGKZSAJXCTH4nm4BWEW_/exec",
      'Wikata Edum Tharagaya': "https://script.google.com/macros/s/AKfycbz-BGsGjoxxVm1nodEZ69YWR0jUWI1zawaszchuOwWWU6gCgUYQmqQKUoJ8RYJPcV-P/exec",
      'Sangeetha Putu Tharagaya': "https://script.google.com/macros/s/AKfycbxLyuq_ZM2mCCHPtJH8YAzeCyYxZeBBmQ8wkFQXbq6EEPyBOje3DeSqypyx4NSgABM-/exec",
      'Gaslabu Gediye Ata Ganan Kirima': "https://script.google.com/macros/s/AKfycbxwepNoGyZHonoCYQkOwvklWxs9sxqekHIBzHFdxl3iUulj027vOigX0BVVms5ewfxN/exec",
      'Suppuwen Wathura Bima': "https://script.google.com/macros/s/AKfycbzBXIQxEwWcoP4AsiXja7_IWZJKVnGrHjFPlG31_sD5r8jRitagCTtSNZx07swGRvnE/exec",
      'Kantha Diwime Tharagaya(married)': "https://script.google.com/macros/s/AKfycbyXMc03OMS41SG0TexqxovZmqq_zGVmEnHLX3M8XE91s83a0wf6yHhh77EXlndxzkEd/exec",
      'Kantha Diwime Tharagaya (unmarried)': "https://script.google.com/macros/s/AKfycbzBw0Dvfa2TSrxp-L48k1fqHhMmcDLjSuZJ7JMVGxv-TZBfBytRDqwbSJFsnKdVlk3B/exec",
      'Pirimi Diwime Tharagaya (married)': "https://script.google.com/macros/s/AKfycbwmapyytkJijKTqz0Je62k-3-f6o6n5iEPx4bL0coYOLWt2TnN0kJ3fir3lLEQTF2AO/exec",
      'Pirimi Diwime Tharagaya (umarried)': "https://script.google.com/macros/s/AKfycbyDmC8djT_T7PHYSz2z04Qu0ScjEnUWVsnFnMlDsmYyUJ-hpgYYWElJUBVMQcHg9XPw/exec",
      'Polla Nalale Thiyan Kerakewima': "https://script.google.com/macros/s/AKfycbwlyX0FEWdoR4rRQIVX_uSbRv_3zdpsYNGlqnmDcT2L9UWz_iQ66dZBtY5_nUsDMokz/exec",
      'Bothalayata wathura pirawima': "https://script.google.com/macros/s/AKfycby7D4vmJTUGhNNvnWRUb8E-7kUcKygKf8Q0_N_Hh4_MAx92Xi3EkzkCd6Y6WwQzczQ_/exec",
      'Tofee Ahidima': "https://script.google.com/macros/s/AKfycbzS6UsZx_QYrWFs9OpP6rSx8LyX7jwyAeOQ-QuOe_uyp9byuOEMUdO0C9jXV9-FCO_o/exec",
      'Thoppi Tharagaya': "https://script.google.com/macros/s/AKfycbzP23ayokaW8R9ENQAr3VCcw805XB_AHTW_cHIY1h0Tr30UAHQS0woOnEshfpFY8sOh/exec",
      'Bola Ellima': "https://script.google.com/macros/s/AKfycbwCUrY6miZewupD0Us7CyV6tIHFEp9VNWLUIn46V9yMLkAVsdn6fVvisN59TVC3FrcM/exec",
      'Mallum Liyawima': "https://script.google.com/macros/s/AKfycbzN8OMv1aZoalCgE3c4TslmZTtaDOp5uHa-1Yk36cyedd4u6MoHrCSXVjdNBl4XC58/exec",
      'Serappu Thragaya': "https://script.google.com/macros/s/AKfycbwq5mC4n8mc4i89rddykEjvnadM9qMit4ZQH4NLFo8tMbxhjZxaHI0jK7N4lOoeTbhj/exec",
      'Wikattuwata Bolaya Demima' : "https://script.google.com/macros/s/AKfycbxxi72xBsTF4meSKDB5vqJnJmowuTPP4I1rD0l6hNGTFhOrUi-OTBnXFtxineeuxVxW/exec",
      'Balloon Dance':"https://script.google.com/macros/s/AKfycbzoEMgjuLetWZMEnk7tcoI6nv8dcdrdu7OppM0AjBJYqnbgolEJI7tJeEVbH9vufYsP/exec"
      // Add more events here
    };
    return eventUrls[event];
  }

  function Submit(e) {
    setIsLoading(true);
    e.preventDefault();
    const selectedEventValues = formData.selectedEvents.map(e => e);
    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);
    const fetchPromises = [];
  
    for (const events of selectedEventValues) {
      const fetchPromise = fetchEvent(events, formDatab);
      fetchPromises.push(fetchPromise);
    }
  
    Promise.all(fetchPromises).then(() => {
      document.getElementById('fullName').value = '';
      setIsLoading(false);
      removeAllFavourites(); // Call removeAllFavourites after form submission
      Swal.fire({
        title: "You are successfully registered.",
        text: "Do you want to make another registration?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "No",
        cancelButtonText: "Yes"
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/');
        } else {
          document.getElementById('fullName').value = '';
          uncheckAllCheckboxes();
        }
      });
    });
  }
  

  // function Submit(e) {
  //   setIsLoading(true)
  //   e.preventDefault();
  //   const selectedEventValues = formData.selectedEvents.map(e => e);
  //   const formEle = document.querySelector("form");
  //   const formDatab = new FormData(formEle);
  //   const fetchPromises = [];

  //   for (const events of selectedEventValues) {
  //     const fetchPromise = fetchEvent(events, formDatab);
  //     fetchPromises.push(fetchPromise);
  //   }

  //   Promise.all(fetchPromises).then(() => {
  //     document.getElementById('fullName').value = '';
  //     setIsLoading(false)
  //     removeAllFavourites()
  //     Swal.fire({
  //       title: "You are successfully registered.",
  //       text: "Do you want to make another registration?",
  //       icon: "success",
  //       showCancelButton: true,
  //       confirmButtonColor: "#d33",
  //       cancelButtonColor: "#3085d6",
  //       confirmButtonText: "No",
  //       cancelButtonText: "Yes"
  //     }).then((result) => {
        
  //       if (result.isConfirmed) {
  //         navigate('/');
  //       } else {
  //         document.getElementById('fullName').value = '';
  //         uncheckAllCheckboxes();
  //       }
  //     });
  //   });
  // }

  function uncheckAllCheckboxes() {
    document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      checkbox.checked = false;
    });
  }


  async function fetchEvent(event, formData) {
    const url = getUrlForEvent(event);
    await fetch(url, {
      method: "POST",
      body: formData
    });
  }


  return (
    <>
      <section className='Registration-for-game-section'>
        <div className='Registration-for-game-section-header'>
          <h1>Game registration</h1>
          <p>Join us in celebrating Sinhala Hindu New Year! Register now to immerse yourself in festivities and traditions</p>
        </div>
        <form onSubmit={(e) => Submit(e)}>
          <div className='Registration-for-game-form-area'>
            <div className='Registration-for-game-form-area-input-group'>
              <label htmlFor="fullName">Full name</label>
              <input type="text" id="fullName" name="fullName" value={formData.fullName} onChange={handleInputChange} required />
            </div>

            <h1 style={{ color: 'gray', fontSize: '1rem' }}>Please select the games</h1>
            <div className='filtering-area'>
              <div className="form-element-filter">
                <input type="radio" name="platform" value="All" id="All" onChange={handleFilterChange} checked={selectedFilter === 'All'} />
                <label for="All">

                  <div class="title">
                    All
                  </div>
                </label>
              </div>
              <div className="form-element-filter">
                <input type="radio" name="platform" value="Open" id="Open" onChange={handleFilterChange} checked={selectedFilter === 'Open'} />
                <label for="Open">

                  <div class="title">
                    Open
                  </div>
                </label>
              </div>
              <div className="form-element-filter">
                <input type="radio" name="platform" value="Team" id="Team" onChange={handleFilterChange} checked={selectedFilter === 'Team'} />
                <label for="Team">

                  <div class="title">
                  Team
                  </div>
                </label>
              </div>

              <div className="form-element-filter">
                <input type="radio" name="platform" value="Couple" id="Couple" onChange={handleFilterChange} checked={selectedFilter === 'Couple'} />
                <label for="Couple">

                  <div class="title">
                    Couple
                  </div>
                </label>
              </div>
              <div className="form-element-filter">
                <input type="radio" name="platform" value="Kids" id="Kids" onChange={handleFilterChange} checked={selectedFilter === 'Kids'} />
                <label for="Kids">

                  <div class="title">
                    Kids
                  </div>
                </label>
              </div>

            </div>
            <h5 style={{ color: 'gray', fontSize: '0.9rem' }}>{selectedFilter === 'Team' || selectedFilter === 'All' ? 'Only the team leader needs to register for team-based games' : ''}</h5>
            <div className='events-list-in-register-game'>
              {eventList.map((eventItem, index) => (
                (selectedFilter === 'All' || eventItem.can_participate.includes(selectedFilter)) ? (
                  <div className="form-element">
                    <input type="checkbox" name="platform" value={eventItem.value} id={eventItem.value} onChange={handleCheckboxChange} checked={formData.selectedEvents.includes(eventItem.value)} />
                    <label htmlFor={eventItem.value}>
                      <div className="title">
                      {language === "Sinhala" ? eventItem.event_name:eventItem.event_name_eng}
                      </div>
                    </label>
                  </div>
                ) : []
              ))}
            </div>

          </div>
          <button type="submit">Submit</button>
        </form>
        {isLoadnig && <section className={`loadingPage ${isLoadnig === true ? 'active-loading' : 'deactive-loading'}`}>
          <span class="loader"></span>
          <h1>Please Wait....</h1>
        </section>}
      </section>
    </>

  );
};

export default RegisterForGame;
