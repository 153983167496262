const eventList = [
    {
        eventId:2,
        event_name:"කඹ ඇදීම",
        event_name_eng:'Tug of war',
        value:"Kamba Edima",
        label:"කඹ ඇදීම",
        event_details:"Get ready for an intense showdown in the ultimate test of strength! The goal is to move the rope a certain distance against the other team's pull. It's all about who can tug the hardest! It's a thrilling display of power and determination as teams strive to outmuscle each other and claim victory.So, gather your team, grab that rope, and get ready for some thrilling competition!",
        event_Time:"4.00 PM - 5.00 PM",
        location:"Station 1/2",
        can_participate:"Open Event (7 Per Team)",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:3,
        event_name:"ඉඳි කට්ටට නූල දැමීම",
        event_name_eng:'Thread the needle',
        value:"Idi Kattata Nula Demima",
        label:"ඉඳි කට්ටට නූල දැමීම",
        event_details:"In this game, a team of two, one male and one female, play together.One individual stands in one corner holding a needle, while the other stands in the opposite corner holding the thread. When the game begins, the person holding the thread runs towards the one with the needle, threads it, and together they race back to their starting point. It's a test of speed and coordination where teamwork is crucial!",
        event_Time:"1.30 PM - 2.00 PM",
        location:"Station 3",
        can_participate:"Couple (Opposite Gender)",
        event_orgernizers:[],
        isNewTag:false
        
    },

    {
        eventId:4,
        event_name:"කිරි පැණි කෑම",
        event_name_eng:'Sri Lankan curd relay',
        value:"Kiri Peni Kema",
        label:"කිරි පැණි කෑම",
        event_details:"Are you ready to tantalize your taste buds with the delightful flavor of Sri Lankan curd? This is your opportunity not only to showcase your champain eating skills but also to revel in the thrill of competing against others to become the fastest to finish. All you need is to team up with a partner of the opposite gender and join the game. The boy will be blindfolded, but fear not, the girl can feed him the entire pot of curd for the win, perhaps adding a dash of love to the mix.",
        event_Time:"11.30 AM - 12.30 PM",
        location:"Station 2 ",
        can_participate:"Couple (Opposite Gender)",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:5,
        event_name:"බනිස් කෑම",
        event_name_eng:'Munch the buns',
        value:"Banis Kema",
        label:"බනිස් කෑම",
        event_details:"Eating a bun might seem easy, but in a competition, it's a whole different story! You've got to be the quickest contestant to finish your bun if you want to win this game. Here's the twist: you have to eat the entire bun without using your hands. Picture this: a row of buns hanging from a thread, and you have to sprint as fast as you can towards them and devour one without touching it with your hands. It's all about speed and skill! The quickest muncher will take home the victory.",
        event_Time:"11.00 AM - 11.30 AM",
        location:"Station 2",
        can_participate:"Kids Event (Under 15)",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:6,
        event_name:"අලියාට ඇස තැබීම",
        event_name_eng:'Blind elephant',
        value:"Aliyat Asa Thebima",
        label:"අලියාට ඇස තැබීම",
        event_details:"Have you ever imagined a blind elephant? Well, get ready to witness one! Even better, you have the chance to give it sight by correctly pointing out its eyes. On game day, you'll receive a drawing of an elephant, and the challenge is to mark the eyes accurately. But when you give sight to the elephant, you'll have to be blindfolded yourself. In other words, we'll cover your eyes with a cloth, and you'll have to find the position of the elephant's eye while blindfolded. It's a test of perception and precision like no other!",
        event_Time:"10.30 AM -12.30 AM",
        location:"Station 3",
        can_participate:"Open Event",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:7,
        event_name:"බාධක දිවීම",
        event_name_eng:'Reverse obstacle race',
        value:"Badaka Diwima",
        label:"බාධක දිවීම",
        event_details:"Running solo is one thing, but have you ever raced as a pair? Get ready for an exhilarating challenge where you'll team up with a partner of the opposite gender and sprint towards victory, overcoming obstacles along the way. Your legs will be tied together with your partner's, making it tricky to move swiftly towards the finish line. But here's where it gets even more exciting – you and your partner will face opposite directions! This game demands clear communication and coordination between both of you to conquer the challenges and emerge victorious. Ready to take on the challenge and race towards glory as a dynamic duo?",
        event_Time:"2.00 PM - 2.30 PM",
        location:"Station 3",
        can_participate:"Couple (Opposite Gender)",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:8,
        event_name:"සැඟවුණු අමුත්තා",
        event_name_eng:'Hidden guest ',
        value:"Segawunu Amuththa",
        label:"සැඟවුණු අමුත්තා",
        event_details:"At the 'Bristol Avrudu Event', among the thousands of attendees, there's one individual who holds a special significance. While each of you is valued and important to us, there's one guest who will be keeping their identity under wraps on that day. You might already be familiar with them, or you might meet them for the first time and engage in delightful conversation, unaware of their special status. Throughout the event, we'll provide you with clues about this mystery person, helping you to piece together their identity. If you manage to guess who this special guest is, all you need to do is inform the organizers of their name, and you'll win our special prize for uncovering the hidden guest.",
        event_Time:"10.30 AM - 12.00 PM",
        location:"Stage",
        can_participate:"Open Event",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:9,
        event_name:"කැට ගැසීම",
        event_name_eng:'Dice game',
        value:"Keta Gesima",
        label:"කැට ගැසීම",
        event_details:"They say winning a dice game is all about luck, while others argue it involves skill. Well, now's your chance to put both luck and talent to the test! In this game, you'll roll the dice to aim for the highest possible value to win. Compete in multiple rounds, each time striving to score the highest points and secure your spot as the final player standing. It's a thrilling combination of chance and skill, so roll the dice and let the games begin!",
        event_Time:"10.30 AM - 12.00 PM",
        location:"Station 4",
        can_participate:"Open Event",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:10,
        event_name:"ගෝනි පැනීම",
        event_name_eng:'Gunny bag hop race',
        value:"Goni Penima",
        label:"ගෝනි පැනීම",
        event_details:"Kids adore jumping around, don't they? Sometimes they bounce up and down just for the fun of it. Well, here's a game tailored for those young jumpers. You'll compete in a race by hopping your way to the finish line. But you must keep your legs inside a gunny bag throughout the entire race. If you happen to slip your legs out of the bag, you're out of the game. The first one to hop across the finish line takes home the victory!",
        event_Time:"1.30 PM - 2.00 PM",
        location:"Station 2",
        can_participate:"Kids Event",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:11,
        event_name:"කණා මුට්ටි බිඳීම",
        event_name_eng:'Smash the pot',
        value:"Kana Mutti Bidima",
        label:"කණා මුට්ටි බිඳීම",
        event_details:"As humans, we often abide by certain unwritten societal rules, and one of the biggest ones is: don't go around smashing things. But in this Avurudu game, you can toss that inhibition aside, grab a sturdy pole, and join your fellow celebrants in a Sri Lankan twist on the classic 'smash-the-piñata' game. 'in this game,' a line of clay pots is suspended at a certain height. Among them, one pot—the 'winning' pot—contains flowers. Participants are blindfolded, spun around a few times, and then handed a bamboo pole. Their wild swings and swipes often draw cheers and laughter from the onlookers, especially when the contender ends up getting soaked. The ultimate victor is the one who manages to strike the correct pot containing the flowers",
        event_Time:"11.00 AM - 12.30 PM",
        location:"Station 4",
        can_participate:"Open Event",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:33,
        event_name:"සිගිති අවුරුදු කුමරා /කුමරිය",
        event_name_eng:'Kids new year prince/princess',
        value:"Awurudu Kumara/Kumari (Child)",
        label:"සිගිති අවුරුදු කුමරා /කුමරිය",
        event_details:"In this event, we aim to showcase the beauty and talents of our little ones to the entire Sri Lankan community in the UK. It's not a competition to determine the most beautiful child, but rather a celebration of the joy and happiness our children bring us. Leading professionals in the beauty industry will select a boy and a girl as the Little Prince and Princess. However, we cherish and value all our children like princes and princesses. Let's come together to admire and appreciate the precious gifts our children are to us all.",
        event_Time:"5.30 PM - 6.00 PM",
        location:"Station 2 ",
        can_participate:"Kids (Under 12)",
        event_orgernizers:[],
        isNewTag:false
    },
    {
        eventId:12,
        event_name:"වැඩිහිටි අවුරුදු කුමරා /කුමරිය",
        event_name_eng:'Adult new year prince/princess',
        value:"Awurudu Kumara/Kumari (Adult)",
        label:"වැඩිහිටි අවුරුදු කුමර /කුමරිය",
        event_details:"Each of us possesses unique beauty, and now is the perfect opportunity to celebrate your individuality by taking part in our 'Avrudu Kumara' and 'Avrudu Kumariya' competition. Esteemed professionals from the Sri Lankan beauty industry will carefully evaluate your beauty across various aspects to determine who will be crowned as the most beautiful Sri Lankan prince and princesses of Bristol. It's a chance to embrace and showcase your inner and outer beauty for all to admire and appreciate.",
        event_Time:"5.00 PM - 5.30 PM",
        location:"Station 2 ",
        can_participate:"Open Event",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:13,
        event_name:"බැලුම් පිපිරවීම",
        event_name_eng:'Pop the balloon',
        value:"Balum Pipirawima",
        label:"බැලුම් පිපිරවීම",
        event_details:"We all have a soft spot for balloons, haven't we? You've probably blown up loads of them at birthday parties or during playtime. But have you ever blown up balloons in a competition? If not, get ready to put your lung power to the test and become the fastest person to burst a balloon by blowing it up with your mouth. In this game, all participants will be given identical balloons, and your mission is to burst yours first. So, take a deep breath, muster up your blowing skills, and register for this exciting and fun-filled event!",
        event_Time:"2.00 PM - 2.30 PM",
        location:"Station 2",
        can_participate:"Kids Event (Under 12)",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:14,
        event_name:"දෙහි ගෙඩිය හැන්දේ තියන් දිවීම",
        event_name_eng:'Balance the lime',
        value:"Dehi Gediya Hende Thiyan Diwima",
        label:"දෙහි ගෙඩිය හැන්දේ තියන් දිවීම",
        event_details:"Who says running has to be ordinary? We're spicing things up by adding an extra twist to the race! Not only do you have to be the first to cross the finish line, but you also have to balance a lime on a spoon while you run. Now, it might sound easy to hold the spoon in your hand and keep the lime steady, but here's the catch – you have to hold the spoon in your mouth! It's not just about being the fastest runner; it's about mastering the art of balancing the lime without dropping it while racing to the finish line. Think you've got what it takes? It's time to put your skills to the test and take on this thrilling challenge",
        event_Time:"10.30 AM - 11.00 AM",
        location:"Station 2",
        can_participate:"Ladies Only",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:15,
        event_name:"හොඳම හිනාව",
        event_name_eng:'Best laugh',
        value:"Hodama Hinawa",
        label:"හොඳම හිනාව",
        event_details:"We all know that one friend who laughs like there's no tomorrow at every joke, right? Well, in this game, we're going to put your laughter skills to the test. But it's not just about laughing; you need to laugh out loud and make sure everyone around you can't help but join in with your laughter. It's all about spreading the joy and having a good time, so get ready to loosen up and stretch those laughter muscles!",
        event_Time:"12.30 PM - 1.00 PM",
        location:"Station 4",
        can_participate:"Open Event",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:18,
        event_name:"විකට ඇඳුම් තරඟය",
        event_name_eng:'Fancy dress',
        value:"Wikata Edum Tharagaya",
        label:"විකට ඇඳුම් තරඟය",
        event_details:"We all love dressing up because it's a chance to express ourselves and showcase our unique personalities. But how often do we get to step into someone else's shoes and become a different character entirely? Just like at Comic Con, where you can dress up as your favorite fictional character, at our 'Bristol Avrudu Event,' you'll have the opportunity to do it all over again! You can dress up as anyone you want, and not only will you be judged on your costume, but also on how well you embody the character you're portraying. It's time to dust off those sewing machines and get to work on creating the perfect costume for your performance. And remember the name of the competition: 'Wikata Edum,' which means your costume and act should be funny and entertaining! So, get creative and let your imagination run wild!",
        event_Time:"1.00 PM - 1.30 PM",
        location:"Station 3",
        can_participate:"Open Event",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:19,
        event_name:"සංගීත පුටු තරඟය",
        event_name_eng:'Musical chairs',
        value:"Sangeetha Putu Tharagaya",
        label:"සංගීත පුටු තරඟය",
        event_details:"Musical Chairs isn't just about dancing to lively Sri Lankan beats – it's about having a blast while competing! We'll arrange a circle of chairs, and all you need to do is dance around to the rhythm of the music. When the music suddenly stops, you've got to scramble to find a chair to sit on. If you can't snag one, unfortunately, you're out of the game. But here's the twist: as the game progresses, we'll remove chairs, leaving fewer seats for the remaining players. It's not just about showing off your dance moves; it's also about showcasing your agility as you quickly snatch a chair to secure your spot in the game. So get ready to dance, groove, and dash for those seats!",
        event_Time:"2.30 PM - 3.00 PM",
        location:"Station 4",
        can_participate:"Open Event",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:20,
        event_name:"ගස්ලබු ගෙඩියේ ඇට ගනන් කිරීම",
        event_name_eng:'Papaya seeds',
        value:"Gaslabu Gediye Ata Ganan Kirima",
        label:"ගස්ලබු ගෙඩියේ ඇට ගනන් කිරීම",
        event_details:"While many of you might enjoy eating papaya, here's a chance to do something out of the ordinary with this fruit. In this game, you'll get to guess the number of seeds inside a papaya without cutting it open. We'll have a papaya at the event location, and your task is to estimate how many seeds it contains. Once the game concludes, we'll slice open the papaya and reveal the actual number of seeds inside. The person who guesses the correct number will be crowned as the winner. It's a fun and unique way to test your guessing skills and perhaps discover a hidden talent for papaya seed estimation!",
        event_Time:"10.30 AM - 1.30 PM",
        location:"Stage",
        can_participate:"Open Event",
        event_orgernizers:[],
        isNewTag:false
    },

    {
        eventId:21,
        event_name:"සූප්පුවෙන් වතුර බීම",
        event_name_eng:'Bottle chug challenge',
        value:"Suppuwen Wathura Bima",
        label:"සූප්පුවෙන් වතුර බීම",
        event_details:"Back in our baby days, nursing bottles were like our best pals. Now, it's time to reunite with your old buddy! You'll be drinking water out of a nursing bottle, aiming to be the fastest to finish it. It sounds simple but remember, everyone else in the game is just as skilled as you. So, it's time to start practicing your nursing bottle skills to ensure you come out victorious on game day! Get ready to show off your chugging prowess and reclaim your title as the nursing bottle champion!",
        event_Time:"12.00 PM - 12.30 PM",
        location:"Station 2",
        can_participate:"Kids Event",
        event_orgernizers:[],
        isNewTag:false
    },
    {
        eventId:22,
        event_name:"කාන්තා දිවීමේ තරඟය (අවිවාහක)",
        event_name_eng:'100m women- unmarried',
        value:"Kantha Diwime Tharagaya (unmarried)",
        label:"කාන්තා දිවීමේ තරඟය (අවිවාහක)",
        event_details:"Ladies, if you've got strong and fast legs, then listen up! This is your golden opportunity to become the fastest Sri Lankan girl in Bristol. The Women's 100-meter sprint promises to be an exhilarating adrenaline rush of a game. So, don't wait around – start your practice today to seize victory! Get those legs moving, hone your speed, and get ready to dash towards glory on the big day!",
        event_Time:"3.00 PM - 4.00 PM",
        location:"Station 5",
        can_participate:"Women - Unmarried",
        event_orgernizers:[],
        isNewTag:false
    },
    {
        eventId:30,
        event_name:"කාන්තා දිවීමේ තරඟය (විවාහක)",
        event_name_eng:'100m women-married',
        value:"Kantha Diwime Tharagaya(married)",
        label:"කාන්තා දිවීමේ තරඟය (විවාහක)",
        event_details:"Who said the fun ends with marriage? It's time to unleash the spirited and strong woman within you by participating in the 100-meter sprint for married women. Seize this wonderful opportunity to showcase your skills to your husband and kids. Don't hesitate—start practicing today to claim victory and prove that age is just a number! Let's show everyone what married women are truly capable of!",
        event_Time:"3.00 PM - 4.00 PM",
        location:"Station 5",
        can_participate:"Women - Married",
        event_orgernizers:[],
        isNewTag:false
    },
    {
        eventId:31,
        event_name:"පිරිමි දිවීමේ තරඟය (විවාහක)",
        event_name_eng:'100m men-married',
        value:"Pirimi Diwime Tharagaya (married)",
        label:"පිරිමි දිවීමේ තරඟය (විවාහක)",
        event_details:"Here's your opportunity to claim the title of the fastest married man in Bristol! Step into the nail-biting excitement of the Married Men's 100-meter running game. So, lace up your running shoes, start stretching those legs, and begin practicing today because this won't be an easy victory. Get ready to sprint your way to glory and leave your competitors in the dust! ",
        event_Time:"3.00 PM - 4.00 PM",
        location:"Station 5",
        can_participate:"Men - Married",
        event_orgernizers:[],
        isNewTag:false
    },
    {
        eventId:32,
        event_name:"පිරිමි දිවීමේ තරඟය (අවිවාහක)",
        event_name_eng:'100m men-umarried',
        value:"Pirimi Diwime Tharagaya (umarried)",
        label:"පිරිමි දිවීමේ තරඟය (අවිවාහක)",
        event_details:"Here's your golden opportunity to showcase the strength and speed of youthful vigor! Step up to the challenge of the 100-meter sprint for unmarried men today. This is your moment to demonstrate your agility and power, and we're confident there'll be plenty of young ladies eager to witness who the fastest unmarried man in Bristol truly is. Don't let this chance slip away—seize the opportunity and make your mark on the track!",
        event_Time:"3.00 PM - 4.00 PM",
        location:"Station 5",
        can_participate:"Men - Unmarried",
        event_orgernizers:[],
        isNewTag:false
    },
    {
        eventId:23,
        event_name:"පොල්ල නළලේ තබා කැරකී දිවීම",
        event_name_eng:'Dizzy dash relay',
        value:"Polla Nalale Thiyan Kerakewima",
        label:"පොල්ල නළලේ තබා කැරකී දිවීම",
        event_details:"Get ready for a game that will leave your head spinning and possibly have you falling to the ground in a dizzy heap! In this team game, you'll be tasked with circling around a wooden stick fixed firmly to the ground. Lined up against another team, you'll await the command to dash towards the stick, then quickly place your head on top and spin around it as fast as you can. But that's not all – once you've completed your spins, you'll need to race back to your starting point so your teammate can take over. Good luck trying to run in a straight line after all that spinning! The first team to finish wins the game.",
        event_Time:"3.00 PM - 4.00 PM",
        location:"Station 5",
        can_participate:"Team Event  (6 per team )",
        event_orgernizers:[],
        isNewTag:false
    },
    {
        eventId:24,
        event_name:"බෝතලයට වතුර පිරවීම",
        event_name_eng:'Fill the bottle',
        value:"Bothalayata wathura pirawima",
        label:"බෝතලයට වතුර පිරවීම",
        event_details:"Filling a water bottle is a breeze, right? But have you ever tried to fill one using just your bare hands? Well, get ready for a unique challenge! In this game, you'll be provided with a basin filled with water. Your mission is to scoop up water from the basin using your hands and fill the bottle as quickly as possible. The first person to successfully fill their bottle wins the game. Best of luck trying to plug those small leaks in your hands as you pour water into the bottle! ",
        event_Time:"2.30 PM - 3.00 PM",
        location:"Station 2",
        can_participate:"Kids Event",
        event_orgernizers:[],
        isNewTag:false
    },
    {
        eventId:25,
        event_name:"ටොෆි ඇහිඳීම",
        event_name_eng:'Toffee hunt',
        value:"Tofee Ahidima",
        label:"ටොෆි ඇහිඳීම",
        event_details:"Get ready for a mouthwatering challenge! In this delectable game, toffees will be scattered on the ground, and your mission is to sprint towards them as fast as you can, collecting as many as possible along the way. The player who gathers the most toffees will be declared the winner. But here's the sweetest part – your reward for winning is all the toffees you've collected! So go ahead, indulge yourself and enjoy the delicious taste of victory!",
        event_Time:"1.00 PM - 1.30 PM",
        location:"Station 2",
        can_participate:"Kids Event",
        event_orgernizers:[],
        isNewTag:false
    },
    {
        eventId:26,
        event_name:"තොප්පි තරඟය",
        event_name_eng:'Musical hats',
        value:"Thoppi Tharagaya",
        label:"තොප්පි තරඟය",
        event_details:"Get ready for a lively game set to the beat of your favorite Sri Lankan tunes! In this fun game, all participants stand in a circle, passing a cap around to the rhythm of the music. Your task is to correctly place the cap on the head of the person standing next to you. But here's the catch – when the music stops, if you're the one left wearing the cap, you're out of the game! So, stay alert and quick on your feet to pass that cap on to the next person in line. Let the fun and excitement begin!",
        event_Time:"12.30 PM - 1.00 PM",
        location:"Station 2",
        can_participate:"Open Event",
        event_orgernizers:[],
        isNewTag:false
    },
    {
        eventId:27,
        event_name:"බෝල ඇල්ලීම",
        event_name_eng:'Couples catch duel',
        value:"Bola Ellima",
        label:"බෝල ඇල්ලීම",
        event_details:"For boys, catching a ball might not seem like a big deal. But in this game, you'll have to catch it as a couple! Grab a partner of the opposite gender and get ready to join the fun. All participants will be lined up at an equal distance from their partner. The challenge is to keep passing and catching the ball between you and your partner, aiming to be the last couple standing. If either of you drops the ball, it's game over for your team. It's all about teamwork, coordination, and staying focused to outlast the competition!",
        event_Time:"12.30 PM - 1.00 PM",
        location:"Station 3",
        can_participate:"Couple (Opposite Gender)",
        event_orgernizers:[],
        isNewTag:false
    },
   
    {
        eventId:29,
        event_name:"සෙරෙප්පු තරඟය",
        event_name_eng:'Match the flip flop',
        value:"Serappu Thragaya",
        label:"සෙරෙප්පු තරඟය",
        event_details:"Get ready to step into a new pair of slippers! In this exciting game, slippers will be scattered on the ground, and your mission is to find the matching pair. All participants will be lined up a few meters away from the slippers. When the game begins, quickly dash towards the slippers and slip your feet into a matching pair. The first person to find a matching pair of slippers wins the game. So lace up those running shoes and get ready to sprint for the win!",
        event_Time:"1.00 PM - 1.30 PM",
        location:"Station 2",
        can_participate:"Open Event",
        event_orgernizers:[],
        isNewTag:false
    },
    {
        eventId:1,
        event_name:"කොට්ට පොර",
        event_name_eng:'Balanced brawl',
        value:"Kotta Pora",
        label:"කොට්ට පොර",
        event_details:"Get ready for the thrilling game of Kotta Pora! With one hand behind your back, you wield pillows in hand, ready to knock each other off the pole. But here's the twist: you're not just sitting on any old surface, oh no! You're perched on a wooden beam, relying solely on your legs and feet to maintain balance. It's a test of skill, agility, and pure determination as you battle it out to reign supreme. So, grab your pillow and get ready to witness the excitement of Kotta Pora!",
        event_Time:"11.00 AM- 2.00 PM",
        location:"Station 1",
        can_participate:"Open Event (Over 18)",
        event_orgernizers:[],
        isNewTag:false
    },
    {
        eventId:34,
        event_name:"විකට්ටුවට බෝලය දැමීම",
        event_name_eng:'Hit the wickets',
        value:"Wikattuwata Bolaya Demima",
        label:"විකට්ටුවට බෝලය දැමීම",
        event_details:"Calling all cricket enthusiasts in Bristol! Cricket runs deep in the veins of every Sri Lankan, and now's your chance to showcase your skills. At our 'Avrudu Event', we'll have a cricket pitch ready and waiting. You'll receive 6 balls, and your objective is to bowl those 6 balls and hit the wicket on the other side. The person with the highest number of wicket hits will emerge victorious. So, stretch those arms and hone your line and length—it's time to aim for the wickets and show off your cricketing prowess!",
        event_Time:"1.00 PM - 2.00 PM",
        location:"Station 1",
        can_participate:"Open Event",
        event_orgernizers:[],
        isNewTag:true
    },
    {
        eventId:35,
        event_name:"බැලුම් නැටුම",
        event_name_eng:'Balloon Dance',
        value:"Balloon Dance",
        label:"බැලුම් නැටුම",
        event_details:"Attention all skilled couple dancers! Here's your chance to shine on the dance floor. Simply dance with your partner to the rhythm of the music, but here's the twist: you must balance a balloon on your forehead while dancing. Dropping your balloon will result in disqualification from the game. The last pair standing will be crowned victorious. So, grab your partner, hit the dance floor, and let the balancing act begin!",
        event_Time:"3.00 PM - 3.30 PM",
        location:"Station 3",
        can_participate:"Couple",
        event_orgernizers:[],
        isNewTag:true
    },

]

export default eventList;