/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./NavBar.css";
import { Link, useNavigate } from "react-router-dom";
import KottuHutLog from '../../img/KottuhutNewLogo.webp'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie';
import { Tooltip } from "antd";
import { useFavourites } from '../../context/FavouritesContext';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

function Navbar() {
	const navRef = useRef();
	const navigate = useNavigate();

	const { favourites } = useFavourites();
	const favouritesSize = favourites.length;

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};

	const handleClick = () => {
		navigate('/');
	};

	const handleClickFavGame = () => {
		navigate('/register-for-game');
	};


	const onSelectLanguage = (e) => {
		Swal.fire({
			text: "In which language would you like game titles to be displayed?",
			iconHtml: "?",
			showCancelButton: true,
			confirmButtonColor: "#030c2e",
			cancelButtonColor: "#030c2e",
			confirmButtonText: "Sinhala",
			cancelButtonText: "English"
		  }).then((result) => {
			if (result.isConfirmed) {
			  Cookies.set('preferred_language', 'Sinhala', { expires: 7 }); // Cookie expires in 7 days
			  navigate(window.location.pathname);
			} else {
			  Cookies.set('preferred_language', 'English', { expires: 7 }); // Cookie expires in 7 days
			  navigate(window.location.pathname);
			}
		  });
	};


	return (
		<header>
			<img style={{ height: '80%' }} src={KottuHutLog} alt="Kottu-hut" onClick={handleClick} />
			<nav ref={navRef}>
				<Link to="/" onClick={showNavbar}>Home</Link>
				<Link to="/about" onClick={showNavbar}>About</Link>
				<Link to="/events" onClick={showNavbar}>Games</Link>
				<Link to="/register" onClick={showNavbar}>Event Registration</Link>
				<Link to="/register-for-game" onClick={showNavbar}>Game Registration</Link>
				<Link to="/local-businesses" onClick={showNavbar}>Local businesses</Link>

				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button>
			</nav>
			<Tooltip title="Select Language" ><a className="laguageSelectButton" style={{color:'blue',cursor:'pointer'}} onClick={onSelectLanguage}> <span class="material-symbols-outlined">language</span></a></Tooltip>
			
			<div className="fav-cart-container" onClick={handleClickFavGame}>
				{favouritesSize <= 0 ?<FavoriteBorderIcon /> : <FavoriteIcon/>

				}
			
			<h1>{favouritesSize}</h1>

			</div>
			<button
				className="nav-btn"
				onClick={showNavbar}>
				<FaBars />

			</button>
		</header>
	);
}

export default Navbar;