/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import "./homePage.css";
import ImgKambaEdima from '../../img/EventImage01/Bristol Avrudu/Kamba edima mobile.webp'
import ImgAliyataEsa from '../../img/EventImage01/Bristol Avrudu/Aliyata esa mobile.webp'
import ImgAwuruduKumaraKumari from '../../img/EventImage01/Bristol Avrudu/Singithi avrudu kumara.webp'
import ImgKanaMuttu from '../../img/EventImage01/Bristol Avrudu/kana mutti mobile.webp'
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const HomePage = () => {

  const navigate = useNavigate();
  const [language, setLanguage] = useState()

  useEffect(() => {
    window.scrollTo(0, 0);
    const language = Cookies.get('preferred_language');
    setLanguage(language)
  }, []);

  const preferredlanguage = Cookies.get('preferred_language');
  useEffect(() => {
    setLanguage(preferredlanguage)
  }, [preferredlanguage]);


  const handleClick = (eventId) => {
    navigate(`/event-details/${eventId}`);
  };
  const heroPageEventList = [
    {
      eventId: 2,
      event_name: "කඹ ඇදීම",
      event_name_eng: 'Tug of war',
      value: "Kamba Edima",
      label: "කඹ ඇදීම",
      event_details: "Get ready for an intense showdown in the ultimate test of strength! The goal is to move the rope a certain distance against the other team's pull. It's all about who can tug the hardest! It's a thrilling display of power and determination as teams strive to outmuscle each other and claim victory.So, gather your team, grab that rope, and get ready for some thrilling competition!",
      event_start_time: "",
      event_end_time: "",
      can_participate: "Open Event",
      event_orgernizers: []
    },
    {
      eventId:6,
      event_name:"අලියාට ඇස තැබීම",
      event_name_eng:'Blind elephant',
      value:"Aliyat Asa Thebima",
      label:"අලියාට ඇස තැබීම",
      event_details:"Have you ever imagined a blind elephant? Well, get ready to witness one! Even better, you have the chance to give it sight by correctly pointing out its eyes. On game day, you'll receive a drawing of an elephant, and the challenge is to mark the eyes accurately. But when you give sight to the elephant, you'll have to be blindfolded yourself. In other words, we'll cover your eyes with a cloth, and you'll have to find the position of the elephant's eye while blindfolded. It's a test of perception and precision like no other!",
      event_start_time:"",
      event_end_time:"",
      can_participate:"Open Event",
      event_orgernizers:[]
  },


    {
      eventId: 11,
      event_name: "කණා මුට්ටි බිඳීම",
      event_name_eng: 'Smash the pot',
      value: "Kana Mutti Bidima",
      label: "කණා මුට්ටි බිඳීම",
      event_details: "As humans, we often abide by certain unwritten societal rules, and one of the biggest ones is: don't go around smashing things. But in this Avurudu game, you can toss that inhibition aside, grab a sturdy pole, and join your fellow celebrants in a Sri Lankan twist on the classic 'smash-the-piñata' game. 'In kana mutti bindeema,' a line of clay pots is suspended at a certain height. Among them, one pot—the 'winning' pot—contains flowers. Participants are blindfolded, spun around a few times, and then handed a bamboo pole. Their wild swings and swipes often draw cheers and laughter from the onlookers, especially when the contender ends up getting soaked. The ultimate victor is the one who manages to strike the correct pot containing the flowers",
      event_start_time: "",
      event_end_time: "",
      can_participate: "Open Event",
      event_orgernizers: []
    },

    {
      eventId:33,
        event_name:"සිගිති අවුරුදු කුමරා /කුමරිය",
        event_name_eng:'Kids new year prince/princess',
        value:"Awurudu Kumara/Kumari (Child)",
        label:"සිගිති අවුරුදු කුමරා /කුමරිය",
        event_details:"In this event, we aim to showcase the beauty and talents of our little ones to the entire Sri Lankan community in the UK. It's not a competition to determine the most beautiful child, but rather a celebration of the joy and happiness our children bring us. Leading professionals in the beauty industry will select a boy and a girl as the Little Prince and Princess. However, we cherish and value all our children like princes and princesses. Let's come together to admire and appreciate the precious gifts our children are to us all.",
        event_start_time:"",
        event_end_time:"",
        can_participate:"Kids (Under 12)",
        event_orgernizers:[]
    },
  ]

  return (
    <>
      <section className='hero-area'>
        <main>

        </main>
      </section>
      <section className='short-des-area'>
        <div class="short-des-area-container" >
          <div class="short-des-area-text" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
            <h1 style={{fontSize:'2rem',marginBottom:'10px'}}>Sinhala Hindu New Year Festival </h1>
            <p className='short-des-area-text-para'>Come along for a delightful Sinhala Hindu New Year celebration, organized by Kottu Hut!
              This event promises a day bursting with cheerfulness, laughter, and boundless fun, as we pay tribute to Sri Lanka’s most beloved cultural festival.

              Get swept up in the thrilling games and marvel at the seasonal wonders that make this time of year so special.
              Let's celebrate together!
            </p>
            <p style={{ fontSize: 15, margin: 10 ,fontWeight:'bold'}}>April 14, 2024 </p>
            <div className='home-page-short-dec-time-container'>
            <p style={{ fontSize: 15, margin: 10, fontWeight:'bold' }}>Main Event : 10 AM-6 PM</p>
            <p style={{ fontSize: 15, margin: 10, fontWeight:'bold' }}>After Party : 6 PM-10.30 PM</p>
            </div>
            <p style={{ fontSize: 15, margin: 10,fontWeight:'bold' }}>Location : Blaise Castle.</p>

            <div>
               <Link to='register'><button>Event registration</button></Link>
            <Link to='register-for-game'><button>Game registration</button></Link>
            </div>
           
          </div>
        </div>
      </section>
      <section className='event-list-area'>
        <div class="event-list-area-container" >

          <div className='events-area'>
            {heroPageEventList.map((heroPageEventItem, index) => (
              <div class="events-area-a-box" data-aos="flip-left" data-aos-duration="1500" onClick={() => handleClick(heroPageEventItem.eventId)}>
                <div class="events-area-img-container">
                  <div class="events-area-img-inner">
                    <div class="events-area-inner-skew">
                      <img class={`hero_game__img ${heroPageEventItem.eventId === 2 ? "hero_game__img-displayBlock" : "hero_game__img-displayNone"}`} src={heroPageEventItem.eventId === 2 ? ImgKambaEdima : ''} alt="people" />
                      <img class={`hero_game__img ${heroPageEventItem.eventId === 6 ? "hero_game__img-displayBlock" : "hero_game__img-displayNone"}`} src={heroPageEventItem.eventId === 6 ? ImgAliyataEsa : ''} alt="people" />
                      <img class={`hero_game__img ${heroPageEventItem.eventId === 11 ? "hero_game__img-displayBlock" : "hero_game__img-displayNone"}`} src={heroPageEventItem.eventId === 11 ? ImgKanaMuttu : ''} alt="people" />
                      <img class={`hero_game__img ${heroPageEventItem.eventId === 33 ? "hero_game__img-displayBlock" : "hero_game__img-displayNone"}`} src={heroPageEventItem.eventId === 33 ? ImgAwuruduKumaraKumari : ''} alt="people" />
                    </div>
                  </div>
                </div>
                <div class="events-area-text-container">
                  <h3>{language === "Sinhala" ? heroPageEventItem.event_name : heroPageEventItem.event_name_eng}</h3>

                </div>
              </div>
            ))}
          </div>
          <Link to="/events"><button className='event-view-more'>View More</button></Link>

        </div>
      </section>

      <section class="organized-team">
        <div class="organized-team-text" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
          <h1 style={{ color: '#000000' }}>Event Organised By <br /><span style={{ color: '#000000' }}>KOTTU  HUT</span></h1>
        </div>
      </section>

      <section className='location-area'>
        <div class="location-area-container" >
          <div class="location-area-title">
            <h2>Location</h2>
          </div>
          <div className='map-container'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.5787387776268!2d-2.6399330239291445!3d51.50259757181215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487192662016d57d%3A0x3939f35daf909ca5!2sBlaise%20Castle%20Estate!5e0!3m2!1sen!2slk!4v1707454975175!5m2!1sen!2slk" style={{ width: "100%", height: "100%", style: "border:0;", allowfullscreen: "", loading: "lazy", referrerpolicy: "no-referrer-when-downgrade" }}></iframe>
          </div>
        </div>
      </section>
    </>

  )
}

export default HomePage