/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from 'react'
import './About.css'
import { Link } from 'react-router-dom';

import KottuHutLogo from '../../img/Partners Logo/Kottu Hut-01.svg'

const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className='about-section'>
       <div className='logo-kottu-hut-about'  data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
          <img style={{width:'100%',height:'100%'}} src={KottuHutLogo} alt='kottu hut'/>
        </div> 
      <div className='about-content-container' style={{width:'100%',display:'flex',padding:'10px 20px',alignItems:'center',justifyContent:'center'}}>
        
     
      <div className='about-content'>
        <h1>Kottu hut are delighted to sponsor this event as a Bristol based, authentic Sri Lankan restaurant. Currently running for 2 years and been doing catering alongside that for the past 18 years, our dedication to Sri Lankan culture extends beyond our menu. This year, we're excited to host the Sri Lankan New Year celebration at Blaise Castle. Join us for a day of traditional music, lively dances, and, of course, plenty of delicious Sri Lankan food. It's a celebration you won't want to miss, as our community gathers to honor our island's traditions and flavors.</h1>
        <p style={{margin:'10px',fontSize:'1.2rem',fontWeight:'bold',textAlign:'left'}}>All events coordinated by</p>
           <ul className='events-coordinated-team'>
              <li><p>Chamara Athulgala</p></li>
              <li><p>Anupama Athugala</p></li>
              <li><p>Asela Ranjith</p></li>
              <li><p>Maria Thoma</p></li>
              <li><p>Mahinda Madawala</p></li>
              <li><p>Nethmi Nadeeka</p></li>
              <li><p>Hashini Pereira</p></li>
              <li><p>Pasindu Jayathilaka</p></li>
              <li><p>Poornima Uthpalawanna</p></li>
              <li><p>Dinuka Sachith</p></li>
              <li><p>Sachini Vithanage</p></li>
              

           </ul>

          <Link to="/events"><button className='event-view-more'>View Games</button></Link>


      </div> 

      <div className='Contact-Details'>
      <div className='conatct-content-about'>
            <div className='contact-details-item-about' data-aos="fade-right"  data-aos-duration="1500">
              <h2><span style={{fontWeight:'bold'}}>Location : </span>31A Zetland road, Bristol , BS6 7AH</h2>
            </div>
            <div className='contact-details-item-about'  data-aos="fade-left" data-aos-duration="1500">
              <h2><span style={{fontWeight:'bold'}}>Contact : </span> <a href="tel:+44117942 2299">+44 117 942 2299</a></h2>
            </div>
            <div className='contact-details-item-about'  data-aos="fade-right" data-aos-duration="1500">
              <h2><span style={{fontWeight:'bold'}}>Mail : </span> <a href="mailto:athugala_anupama@yahoo.co.uk">athugala_anupama@yahoo.co.uk</a></h2>
            </div>
            <div className='contact-details-item-about' data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1500">
              <h2><span style={{fontWeight:'bold'}}>Web Site : </span> <a href='https://kottuhutbristol.co.uk/'>kottuhutbristol.co.uk</a></h2>
            </div>
            <div data-aos="fade-right" style={{color:'#ffffff'}}>a</div>
         
          </div>
      </div>
      
      </div>
     
      <h1>Location</h1>
      <div className='about-map' style={{width:'100%',display:'flex',padding:'10px 20px',alignItems:'center',justifyContent:'center'}}>
       
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.3715599981088!2d-2.5954685!3d51.469693799999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48718e0a3db6e5fd%3A0x69215a70c13d6d4e!2s31a%20Zetland%20Rd%2C%20Redland%2C%20Bristol%20BS6%207AH%2C%20UK!5e0!3m2!1sen!2slk!4v1710733932137!5m2!1sen!2slk"
          style={{ width: "100%", height: "100%", style: "border:0;", allowfullscreen: "", loading: "lazy", referrerpolicy: "no-referrer-when-downgrade" }}/>
          
          </div>
    </section>
  )
}

export default About