/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import './Partners.css'
import kaino from '../../img/Partners Logo/Kainovation.jpg'
import FLICKA from '../../img/Partners Logo/FilCKA.jpg'
import Candid from '../../img/Partners Logo/Candid.jpg'
import MeuLabs from '../../img/Partners Logo/MeuLab.jpg'
import DanceLesson from '../../img/Partners Logo/dance lesson.webp'

const Partners = () => {
    const [isSeeMore01, setIsSeeMore1] = useState(false)
    const [isSeeMore02, setIsSeeMore2] = useState(false)
    const [isSeeMore03, setIsSeeMore3] = useState(false)
    const [isSeeMore04, setIsSeeMore4] = useState(false)
    const [isSeeMore05, setIsSeeMore5] = useState(false)

    function handleSeeMoreButton(isSeeMore) {
        if (isSeeMore === 1) {
            setIsSeeMore1(isSeeMore01 === true ? false : true)
        }

        if (isSeeMore === 2) {
            setIsSeeMore2(isSeeMore02 === true ? false : true)
        }


        if (isSeeMore === 3) {
            setIsSeeMore3(isSeeMore03 === true ? false : true)
        }


        if (isSeeMore === 4) {
            setIsSeeMore4(isSeeMore04 === true ? false : true)
        }

        if (isSeeMore === 5) {
            setIsSeeMore5(isSeeMore05 === true ? false : true)
        }


    }

    return (
        <section className='partners-section'>
            <div className='partners-section-header'>
                <h1>Local businesses</h1>
                <p>Kottu Hut is proud to support businesses of fellow Bristol Sri Lankans and invite you all to experience the best Sri Lankan products and talents</p>
            </div>
            <div className='partners-container'>
                <div className='partner-item-container'>
                    <div className='partner-item-img' >
                        <img src={MeuLabs} alt='kaino' />
                    </div>
                    <div className='partner-item-content' >
                        <h1 className='partner-name-txt'>Meu Labs</h1>

                        <p className='partner-dec-txt'>Empower your children to tackle real-world challenges head-on with our specialized programs! Whether your child dreams of becoming an engineer, data scientist, economist, or artist, we provide them with the tools to excel in any field of their interest.
                            <span className={`${isSeeMore02 === true ? 'dotNone' : 'dotBlock'}`}>....</span>
                            <span className={`${isSeeMore02 === true ? 'MoreTextBlock' : 'MoreTextNone'}`}> Our approach allows kids to engage in hands-on projects, fostering a mindset of "learning how to learn" through experiential learning. With our custom T-shaped learning ecosystem, we gradually cultivate essential 21st-century skills, shaping your children as future leaders. Rest assured, all our courses are STEM.ORG certified and meticulously curated by industry experts. <br /><br />
                                <ul style={{textAlign:'left',marginLeft:40}}>
                                    <li>For Kids aged 7-16 </li>
                                    <li> Medium: Online (We provide all necessary equipment for project completion)</li>
                                    <li>Duration: 4 Months (2 hours per week, with unlimited individual lessons for additional support)</li>
                                    <li>Investment: One-time payment of £275 or pay by 4 monthly installments of £75.</li>
                                </ul>
                                <br></br>
                                Eager to learn more?<br/> Simply fill out this form, and we'll promptly address all your inquiries.<br/>
                                <a style={{color:'#050d45'}} className='partner-webSite-txt' href='https://forms.gle/j4DGbDwL2Zu7zHU6A' target="_blank">https://forms.gle/j4DGbDwL2Zu7zHU6A </a><br/>

                            </span> <a className='seeMoreButton' onClick={() => handleSeeMoreButton(2)}>{isSeeMore02 === true ? 'See Less' : 'See More'}</a></p>
                        <div className='partner-webSite-txt-container'>
                            <span className="material-symbols-outlined partner-webSite-txt-icon">language</span>
                            <a className='partner-webSite-txt' href='https://meulabs.org/?utm_source=KottuHut&utm_medium=NewYear2024&utm_campaign=UK' target="_blank">https://meulabs.org/</a>
                        </div>

                        <div className='partner-phone-txt-container'>
                            <span class="material-symbols-outlined partner-phone-txt-icon">call</span>
                            <a className='partner-phone-txt' href="tel:+947362787126">07362787126</a>
                        </div>

                    </div>
                </div>

                <div className='partner-item-container'>
                    <div className='partner-item-img' >
                        <img src={FLICKA} alt='kaino' />
                    </div>
                    <div className='partner-item-content' >
                        <h1 className='partner-name-txt'>FLICKA</h1>

                        <p className='partner-dec-txt'>Flicka, a star operation that began half a decade ago and now operates in both Sri Lanka and the UK. We're your ultimate destination for premium fashion experiences at reasonable prices, catering to both gentlemen and ladies with discerning tastes.
                            <span className={`${isSeeMore03 === true ? 'dotNone' : 'dotBlock'}`}>....</span>
                            <span className={`${isSeeMore03 === true ? 'MoreTextBlock' : 'MoreTextNone'}`}><br/><br/>At Flicka, we pride ourselves on curating collections that exude sophistication, elegance, and style. From impeccably tailored suits to chic dresses, each piece in our store is carefully selected to ensure the highest quality and the latest trends.
                                What sets us apart is our commitment to offering luxury fashion without the hefty price tag. We believe that everyone deserves to look and feel their best, which is why we make premium fashion accessible to all.
                                <br/><br/>Trusted among a plethora of celebrities in Sri Lanka, Flicka has become synonymous with quality, reliability, and impeccable style. Our clientele includes some of the most prominent figures in the entertainment industry, who trust us to deliver exceptional clothing and service.
                                <br/><br/>Whether you're attending a red-carpet event or simply want to elevate your everyday wardrobe, Flicka is here to help you make a statement. Our knowledgeable staff is dedicated to providing personalized assistance, ensuring that you find the perfect pieces to express your individuality.
                                Visit us at Facebook and Instagram accounts to experience the Flicka.lk.uk difference for yourself.<br/><br/> Join the ranks of our satisfied customers and discover why we're the preferred choice for premium fashion in Sri Lanka and the UK. Welcome to a world where luxury meets affordability, only at Flicka.</span> <a className='seeMoreButton' onClick={() => handleSeeMoreButton(3)}>{isSeeMore03 === true ? 'See Less' : 'See More'}</a></p>
                        <div className='partner-webSite-txt-container'>
                            <span className="material-symbols-outlined partner-webSite-txt-icon">language</span>
                            <a className='partner-webSite-txt' href='https://www.facebook.com/Flicka.lk' target="_blank">Flicka.lk.uk</a>
                        </div>

                        <div className='partner-phone-txt-container'>
                            <span class="material-symbols-outlined partner-phone-txt-icon">call</span>
                            <a className='partner-phone-txt' href="tel:+441177960667560">+44 117 7960 667560</a>
                        </div>

                    </div>
                </div>

                <div className='partner-item-container'>
                    <div className='partner-item-img' >
                        <img src={Candid} alt='kaino' />
                    </div>
                    <div className='partner-item-content' >
                        <h1 className='partner-name-txt'>Candid Essence</h1>

                        <p className='partner-dec-txt'>Candid Essences, where every moment is captured with authenticity and artistry. Based in the Bristol UK, Candid Essences specialize in the fine craft of candid photography, offering a unique perspective that brings out the true essence of every scene.
                            <span className={`${isSeeMore04 === true ? 'dotNone' : 'dotBlock'}`}>....</span>
                            <span className={`${isSeeMore04 === true ? 'MoreTextBlock' : 'MoreTextNone'}`}><br></br><br></br>At Candid Essences, we believe that the beauty of a moment lies in its spontaneity and naturalness. Our talented photographer possesses a keen eye for detail and a passion for storytelling, allowing us to create captivating images that reflect the genuine emotions at the moment.
                                With years of experience and a portfolio filled with stunning examples of candid photography art, we have earned a reputation for excellence in our field. Whether it's a candid portrait, a candid event, or a candid moment captured in nature, we have the skill and expertise to transform ordinary moments into extraordinary memories.<br/><br/>
                                What sets Candid Essences apart is our dedication to preserving the authenticity of every moment we photograph and videography based on the customers requirements. <br/>We strive to create images that are not only visually striking but also deeply meaningful, evoking emotions and memories that last a lifetime.
                                If you're looking for a talented photographer who specializes in candid photography, look no further than Candid Essences. We're here to immortalize your most precious moments with creativity, passion, and skill. <br/><br/>Contact us today to discuss your photography needs and let us capture the essence of your story.</span> <a className='seeMoreButton' onClick={() => handleSeeMoreButton(4)}>{isSeeMore04 === true ? 'See Less' : 'See More'}</a></p>
                        <div className='partner-webSite-txt-container'>
                            <span className="material-symbols-outlined partner-webSite-txt-icon">language</span>
                            <a className='partner-webSite-txt' href='https://www.facebook.com/profile.php?id=100094543702195' target="_blank">Candid Essence</a>
                        </div>

                        <div className='partner-phone-txt-container'>
                            <span class="material-symbols-outlined partner-phone-txt-icon">call</span>
                            <a className='partner-phone-txt' href="tel:+447960667560">+44 7960 667560</a>
                        </div>

                    </div>
                </div>

                <div className='partner-item-container'>
                    <div className='partner-item-img' >
                        <img src={DanceLesson} alt='kaino' />
                    </div>
                    <div className='partner-item-content' >
                        <h1 className='partner-name-txt'>Dancing lessons</h1>

                        <p className='partner-dec-txt'>What is dance? <br /><br />
                            Dance, the movement of the body in a rhythmic way, usually to music and within a given
                            space, for the purpose of expressing an idea or emotion, releasing energy, or simply taking
                            delight in the movement itself

                            <span className={`${isSeeMore05 === true ? 'dotNone' : 'dotBlock'}`}>....</span>
                            <span className={`${isSeeMore05 === true ? 'MoreTextBlock' : 'MoreTextNone'}`}>
                            <br /><br />Sri Lankan Cultural free style Dancing<br /><br />

                                It plays an important role in our ceremonies and celebrations and helps to maintain the culture of
                                our people by passing down stories, songs and traditions from generation to generation. Dance has been used historically to
                                communicate stories and messages about the land, animals and ancestral spirits.<br /><br />

                                We offer dance classes to different types of customers – kids, high school students, the elderly, middle-aged men and women,
                                or professional and amateur dancers of all ages.<br /><br />

                                Learning a variety of dance styles <br /><br />
                                Learning a variety of dance styles allows for versatility in the way your body is able to translate movement and choreography.<br /><br />

                                Health benefits of dancing<br /><br />
                                <ul style={{textAlign:'left',marginLeft:40}}>
                                     <li>improved condition of your heart and lungs.</li>
                                     <li>increased muscular strength, endurance and motor fitness.</li>
                                     <li>increased aerobic fitness.</li>
                                     <li>improved muscle tone and strength.</li>
                                     <li>weight management.</li>
                                     <li>stronger bones and reduced risk of osteoporosis.</li>
                                     <li>better coordination, agility and flexibility.</li>

                                </ul>



                            </span> <a className='seeMoreButton' onClick={() => handleSeeMoreButton(5)}>{isSeeMore05 === true ? 'See Less' : 'See More'}</a></p>
                            <div className='partner-phone-txt-container'>
                            <span class="material-symbols-outlined partner-phone-txt-icon">call</span>
                            <a className='partner-phone-txt' href="tel:+94773836673">+94773836673 (whatsapp only)</a>
                        </div>

                        <div className='partner-phone-txt-container'>
                            <span class="material-symbols-outlined partner-phone-txt-icon">call</span>
                            <a className='partner-phone-txt' href="tel:++447405118481" >+447405118481</a>
                        </div>

                    </div>
                </div>



                <div className='partner-item-container'>
                    <div className='partner-item-img' >
                        <img src={kaino} alt='kaino' />
                    </div>
                    <div className='partner-item-content' >
                        <h1 className='partner-name-txt'>Kainovation Technologies</h1>

                        <p className='partner-dec-txt'>If you're looking for a skilled Sri Lankan tech team to turn your ideas into a reality, your search ends here.We excel in all aspects of technology and offer top-notch IT services at
                                competitive rates.
                            <span className={`${isSeeMore01 === true ? 'dotNone' : 'dotBlock'}`}>....</span>
                            <span className={`${isSeeMore01 === true ? 'MoreTextBlock' : 'MoreTextNone'}`}> Whether you need a simple website, mobile app, or a sophisticated
                                AI-driven application, our team is equipped to assist you. <br/>Our expertise spans software
                                development, data analytics, machine learning, artificial intelligence, and much more.
                                Additionally, we provide the option to hire remote tech resources from Sri Lanka who can
                                work as dedicated team members for your project.<br/><br/>

                                We specialize in building MVPs (Minimum Viable Products) and transforming innovative
                                ideas into tangible solutions using technology. If you have a business idea you're eager to
                                explore in the UK, don't hesitate to reach out for a free consultation. We understand the
                                challenges of turning your idea into reality and are committed to investing time and effort to
                                help grow your product with our expertise. Let's collaborate and bring your vision to life!<br/><br/>
                            </span> <a className='seeMoreButton' onClick={() => handleSeeMoreButton(1)}>{isSeeMore01 === true ? 'See Less' : 'See More'}</a></p>
                        <div className='partner-webSite-txt-container'>
                            <span className="material-symbols-outlined partner-webSite-txt-icon">language</span>
                            <a className='partner-webSite-txt' href='https://kainovation.com/?utm_source=Kottuhut&utm_medium=Website&utm_campaign=NewYear2024' target="_blank">https://kainovation.com/</a>
                        </div>

                        <div className='partner-phone-txt-container'>
                            <span class="material-symbols-outlined partner-phone-txt-icon">call</span>
                            <a className='partner-phone-txt' href="tel:+947362787126">07362787126</a>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    )
}

export default Partners