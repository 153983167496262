/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import './EventDetails.css'
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import eventList from '../../../data/EventList';
import ImgAliyataEsa from '../../../img/EventImage01/Bristol Avrudu/Aliyata esa mobile.webp'
import ImgAwuruduKumaraKumariAdult from '../../../img/EventImage01/Bristol Avrudu/New Awurudu Kumari .webp'
import ImgAwuruduKumaraKumariChild from '../../../img/EventImage01/Bristol Avrudu/Singithi avrudu kumara.webp'
import ImgBaloonAllima from '../../../img/EventImage01/Bristol Avrudu/balloon mobile.webp'
import ImgBothalayataWathura from '../../../img/EventImage01/Bristol Avrudu/bothalayata wathura mobile.webp'
import ImgDehiGediya from '../../../img/EventImage01/Bristol Avrudu/Dehi gediya mobile.webp'
import ImgHodamaEdima from '../../../img/EventImage01/Bristol Avrudu/Edeema mobile.webp'
import ImgGaslabuGediya from '../../../img/EventImage01/Bristol Avrudu/Gaslabu eta mobile.webp'
import ImgHodamaHinawa from '../../../img/EventImage01/Bristol Avrudu/Hinawa mobile.webp'
import ImgKambaEdima from '../../../img/EventImage01/Bristol Avrudu/Kamba edima mobile.webp'
import ImgKanaMutti from '../../../img/EventImage01/Bristol Avrudu/kana mutti mobile.webp'
import ImgKanthaDiwimaUnMarried from '../../../img/EventImage01/Bristol Avrudu/Kantha diweema mobile.webp'
import ImgKanthaDiwimaMarried from '../../../img/EventImage01/Bristol Avrudu/Kantha diwieema married (1).webp'
import ImgPirimiDiwimaUnMarried from '../../../img/EventImage01/Bristol Avrudu/Pirimi diwiima unmarried.webp'
import ImgPirimiDiwimaMarried from '../../../img/EventImage01/Bristol Avrudu/Pirimi diwiima.webp'
import ImgKetaGesima from '../../../img/EventImage01/Bristol Avrudu/Keta Gesima mobile.webp'
import ImgKiriKewima from '../../../img/EventImage01/Bristol Avrudu/Kiri kewima.webp'
import ImgPollaWate from '../../../img/EventImage01/Bristol Avrudu/Polla wate mobile.webp'
import ImgSangeethaPutu from '../../../img/EventImage01/Bristol Avrudu/Sangeetha putu mobile.webp'
import ImgSegawunuAmuththa from '../../../img/EventImage01/Bristol Avrudu/Segauna amuththa mobile.webp'
import ImgSuppuwenWathura from '../../../img/EventImage01/Bristol Avrudu/Suuppwen wathura mobile.webp'
import ImgToffeEhidima from '../../../img/EventImage01/Bristol Avrudu/Toffee ehindieema mobile.webp'
import ImgWikataEdum from '../../../img/EventImage01/Bristol Avrudu/Wikata edum mobile.webp'
import ImgIdiKattataNulaDemima from '../../../img/EventImage01/Bristol Avrudu/indikatta ta nuula.webp'
import ImgBanisKema from '../../../img/EventImage01/Bristol Avrudu/Banis kema.webp'
import ImgGoniPenima from '../../../img/EventImage01/Bristol Avrudu/Goni penima.webp'
import ImgBolaEllima from '../../../img/EventImage01/Bristol Avrudu/Bola alleema.webp'
import ImgSerappu from '../../../img/EventImage01/Bristol Avrudu/sereppu.webp'
import ImgThoppiTharagaya from '../../../img/EventImage01/Bristol Avrudu/Thoppi tharagaya.webp'
import ImgBadakaDiwima from '../../../img/EventImage01/Bristol Avrudu/Baadaka diweema.webp'
import ImgKottapora from '../../../img/EventImage01/Bristol Avrudu/Kottapora.webp'
import ImgCricket  from '../../../img/EventImage01/Bristol Avrudu/CricketGame.webp'

import ImgBalloonDance from '../../../img/EventImage01/Bristol Avrudu/BallonDance.webp'
import { useFavourites } from '../../../context/FavouritesContext';


const EventDetails = () => {
  const { eventId } = useParams();
  const [eventItem, setEventItem] = useState(null);

  const { addToFavourites } = useFavourites();


  const { favourites } = useFavourites();
  const { removeFromFavourites } = useFavourites();

  const isEventInFavourites = favourites.some(favourite => favourite === eventItem?.value);

  const [language, setLanguage] = useState()

  const preferredlanguage = Cookies.get('preferred_language');
  useEffect(() => {
    setLanguage(preferredlanguage)
  }, [preferredlanguage]);

  


  const handleAddToFavourites = () => {
    if (isEventInFavourites) {
      removeFromFavourites(eventItem?.value);
    } else {
      addToFavourites(eventItem?.value);
    }
  };
  useEffect(() => {
    const selectedEvent = eventList.find((eventItem) => eventItem.eventId == eventId);
    setEventItem(selectedEvent);
  }, [eventId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className='event-details-section'>
      <div className='event-details-container'>
        <div className='event-details-container-image'>
          <img className={`evnet-details-image ${eventItem?.eventId === 1 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 1 ? ImgKottapora : ''} alt="Kotta Pora" />
          <img className={`evnet-details-image ${eventItem?.eventId === 2 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 2 ? ImgKambaEdima : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 3 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 3 ? ImgIdiKattataNulaDemima : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 4 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 4 ? ImgKiriKewima : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 5 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 5 ? ImgBanisKema : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 6 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 6 ? ImgAliyataEsa : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 7 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 7 ? ImgBadakaDiwima : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 8 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 8 ? ImgSegawunuAmuththa : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 9 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 9 ? ImgKetaGesima : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 10 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 10 ? ImgGoniPenima : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 11 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 11 ? ImgKanaMutti : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 12 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 12 ? ImgAwuruduKumaraKumariAdult : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 33 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 33 ? ImgAwuruduKumaraKumariChild : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 13 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 13 ? ImgBaloonAllima : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 14 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 14 ? ImgDehiGediya : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 15 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 15 ? ImgHodamaHinawa : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 16 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 16 ? ImgHodamaEdima : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 17 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 17 ? '' : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 18 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 18 ? ImgWikataEdum : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 19 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 19 ? ImgSangeethaPutu : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 20 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 20 ? ImgGaslabuGediya : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 21 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 21 ? ImgSuppuwenWathura : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 22 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 22 ? ImgKanthaDiwimaUnMarried : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 23 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 23 ? ImgPollaWate : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 24 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 24 ? ImgBothalayataWathura : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 25 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 25 ? ImgToffeEhidima : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 26 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 26 ? ImgThoppiTharagaya : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 27 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 27 ? ImgBolaEllima : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 28 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 28 ? '' : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 29 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 29 ? ImgSerappu : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 30 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 30 ? ImgKanthaDiwimaMarried : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 31 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 31 ? ImgPirimiDiwimaMarried : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 32 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 32 ? ImgPirimiDiwimaUnMarried : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 34 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 34 ? ImgCricket : ''} alt="people" />
          <img className={`evnet-details-image ${eventItem?.eventId === 35 ? "eventsDetailsDisplayBlock" : "eventsDetailsDisplayNone"}`} src={eventItem?.eventId === 35 ? ImgBalloonDance : ''} alt="people" />
        </div>
        <div className='event-details-container-content'>
          <h1 className='event-title'>{language === "Sinhala" ? eventItem?.event_name : eventItem?.event_name_eng}</h1>

          <p style={{ width: '100%', marginTop: 20 }}><span>{eventItem?.event_details}.</span></p>
          <p style={{ width: '70%', marginTop: 5 }}><br></br>
            <span><span style={{ fontWeight: 600, marginBottom: 24 }}>Category  </span><br></br> {eventItem?.can_participate} </span><br></br><br></br>
            <span><span style={{ fontWeight: 600, marginBottom: 24 }}>Time </span> <br></br>{eventItem?.event_Time}</span><br></br> <br></br>
            <span><span style={{ fontWeight: 600, marginBottom: 24 }}>Ground Location </span> <br></br> {eventItem?.location}</span>
          </p>


          <div className='event-organiser-container'>
            <h1 style={{ fontSize: '1rem', marginBottom: 10 }}>Event organiser</h1>
            <span> To be confirmed soon</span>
          </div>
          <p style={{ fontSize: 15, margin: 20 }}>More Details :  <a href="tel:+44117942 2299" style={{ textDecoration: 'none', color: 'rgb(81, 81, 82)', cursor: 'pointer' }}>+44 117 942 2299</a></p>

          <button className={`${isEventInFavourites == true ? 'fav-button-disable' : ''}`} onClick={handleAddToFavourites}>{isEventInFavourites == true ? 'Remove From favourites' : "Add to favourites"}</button>

        </div>
      </div>
    </section>
  )
}

export default EventDetails