/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <footer className='footer'><h1>Copyright © 2024 Kottu Hut | Powered by <span className='kaino-text'><a href='https://kainovation.com/?utm_source=Kottuhut&utm_medium=Website&utm_campaign=NewYear2024' target="_blank">KAINOVATION</a></span></h1>
    </footer>
  )
}

export default Footer