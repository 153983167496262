import './App.css';
import Navbar from './components/app-bar/NavBar';
import About from './pages/about/About';
import Events from './pages/events/Events';
import EventDetails from './pages/events/event-details/EventDetails';
import HomePage from './pages/home/HomePage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from './pages/register/Register';
import RegisterForGame from './pages/register/RegisterForGame';
import Footer from './components/footer/Footer';
import Partners from './pages/partners/Partners';
import { useEffect } from 'react';
import Swal from 'sweetalert2'
import Cookies from 'js-cookie';
import { FavouritesProvider } from './context/FavouritesContext';


function App() {
  useEffect(() => {
    const language = Cookies.get('preferred_language');
    if (!language) {
      Swal.fire({
        text: "In which language would you like game titles to be displayed ?",
        iconHtml: "?",
        showCancelButton: true,
        confirmButtonColor: "#030c2e",
        cancelButtonColor: "#030c2e",
        confirmButtonText: "Sinhala",
        cancelButtonText: "English"
      }).then((result) => {
        if (result.isConfirmed) {
          Cookies.set('preferred_language', 'Sinhala', { expires: 7 }); // Cookie expires in 7 days
          window.location.reload();
        } else {
          Cookies.set('preferred_language', 'English', { expires: 7 }); // Cookie expires in 7 days
          window.location.reload();
        }
      });
    }

  }, []);

  return (
    <>
      <div className='App'>
        <Router>
          <FavouritesProvider>
              <div>
            <Navbar />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<About />} />
              <Route path="/events" element={<Events />} />
              <Route path="/event-details/:eventId" element={<EventDetails />} />
              <Route path="/register" element={<Register />} />
              <Route path="/register-for-game" element={<RegisterForGame />} />
              <Route path="/local-businesses" element={<Partners />} />
            </Routes>

          </div>
          </FavouritesProvider>
        
        </Router>
      </div> <Footer />
    </>

  );
}

export default App;
