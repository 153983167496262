import React, { useEffect, useState } from 'react'
import './Register.css';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
const Register = () => {
    const [formData, setFormData] = useState({});
    const [phoneNumberValid, setPhoneNumberValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [isLoadnig, setIsLoading] = useState(false);
    const [receivePromotionalDiscounts, setReceivePromotionalDiscounts] = useState(true); 
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === 'Contact') {
            setPhoneNumberValid(validatePhoneNumber(value));
        }
        if (name === 'Email') {
            setEmailValid(validateEmail(value));
        }
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhoneNumber = (phoneNumber) => {
        const phoneRegex = /^\+44\d{10}$|^0044\d{10}$|^0\d{10}$/;
        return phoneRegex.test(phoneNumber);
    };

    function Submit(e) {
        setIsLoading(true)
        e.preventDefault();
        const formEle = document.querySelector("form");
        const formDatab = new FormData(formEle);

        formDatab.append("Receive_Promotional_Discounts", receivePromotionalDiscounts ? "Yes" : "No");

        fetch(
            "https://script.google.com/macros/s/AKfycbzpQ6wpn73IzGVd-03qI7RTaL8uL-okn1gDihKrWCd6IJ6IFrfjkHm80OXoeYfPWAGT/exec",
            {
                method: "POST",
                body: formDatab
            }
        )
            .then((res) => {
                if (res.ok) {
                    setIsLoading(false)
                    Swal.fire({
                        title: "You are successfully registered.",
                        text: "Do you want to make another registration ?",
                        icon: "success",
                        showCancelButton: true,
                        confirmButtonColor: "#d33",
                        cancelButtonColor: "#3085d6",
                        confirmButtonText: "No",
                        cancelButtonText: "Yes"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate('/');
                        }
                    });
                    setFormData({});
                    document.getElementById('fname').value = '';
                    document.getElementById('lname').value = '';
                    document.getElementById('email').value = '';
                    document.getElementById('contact').value = '';
                    document.getElementById('adults').value = '';
                    document.getElementById('child').value = '';
                    setReceivePromotionalDiscounts(false)
                } else {
                    console.error('Error submitting form');
                }
            })
            .then((data) => {

            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleCheckboxChange = (event) => {
        setReceivePromotionalDiscounts(event.target.checked);
    };

    return (
        <section className='Registration-section'>
            <div className='Registration-section-header'>
                <h1>Event registration</h1>
                <p>Join us in celebrating Sinhala Hindu New Year! Register now to immerse yourself in festivities and traditions</p>
            </div>

            <form onSubmit={(e) => Submit(e)}>

                <div className='Registration-form-area'>

                    <div className='Registration-form-area-input-group'>
                        <label for="fname">First name</label>
                        <input type="text" id="fname" name="First_Name" onChange={handleInputChange} required />
                    </div>

                    <div className='Registration-form-area-input-group'>
                        <label for="fname">Last name</label>
                        <input type="text" id="lname" name="Last_Name" onChange={handleInputChange} required />
                    </div>

                    <div className='Registration-form-area-input-group'>
                        <label for="fname">Email</label>
                        <input type="text" id="email" name="Email" onChange={handleInputChange} />
                        {!emailValid && <small style={{ color: 'red' }}>Invalid Email</small>}
                    </div>

                    <div className='Registration-form-area-input-group'>
                        <label for="fname">Phone Number</label>
                        <input type="text" id="contact" name="Contact" onChange={handleInputChange} />
                        {!phoneNumberValid && <small style={{ color: 'red' }}>Invalid Number</small>}
                    </div>

                    <div className='Registration-form-area-input-group'>
                        <label for="fname">How many people are you bringing (Including you)</label>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '90%', padding: '5px 5px' }}>
                                <input type="text" id="adults" name="Adults" placeholder='Adults' onChange={handleInputChange} required />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '90%', padding: '5px 5px' }}>
                                <input type="text" id="child" name="Child" placeholder='Children' onChange={handleInputChange} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='checkbox-promotional-discount' style={{ textAlign: 'left', marginLeft: "50px" }}>
                    <FormControlLabel

                        value="end"
                        control={<Checkbox 
                            checked={receivePromotionalDiscounts}
                            onChange={handleCheckboxChange}
                        />}
                        label="I want to receive promotional discounts from local businesses."
                        labelPlacement="I want to receive promotional discounts from local businesses."
                    />
                </div>

                <button>Submit</button>

            </form>
            {isLoadnig && <section className={`loadingPage ${isLoadnig === true ? 'active-loading' : 'deactive-loading'}`}>
                <span class="loader"></span>
                <h1>Please Wait....</h1>
            </section>}
        </section>
    )
}

export default Register